import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';
import React, {memo} from 'react';
import AppConfig from '../../config/appConfig';
import useGetLatestPosts from '../../hooks/useGetLatestPosts';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import CardWrapper from '../Wrapper/CardWrapper';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import StyledHeading from '../_baseComponents/StyledHeading/StyledHeading';

/**
 * A Post Widget that displays latest posts. The number of items to be displayed can be configured in `AppConfig.postLatestLength`
 * @param {Object} props
 * @param {string} props.postId ID of current post. This ID will be used to filter the result.
 * @returns
 */
const PostWidgetLatestPosts = ({
  postId,
  className = 'mb-5 bg-white/30 p-5 lg:p-6',
  listClassName,
}) => {
  const {latestPosts} = useGetLatestPosts();

  return (
    <>
      {latestPosts?.nodes?.length > 0 && (
        <CardWrapper className={`${className}`}>
          <StyledHeading tag={'h2'} className="mb-4 text-xl font-semibold">
            Bài viết mới nhất
          </StyledHeading>
          <ul className={`${listClassName}`}>
            {latestPosts.nodes
              .filter((post) => post.id !== postId)
              .slice(0, AppConfig.postLatestLength)
              .map((post) => (
                <li key={post.id} className="group mb-5 last:mb-0">
                  <UniversalLink to={post.uri} className="grid grid-cols-10">
                    {post.featuredImage?.node ? (
                      <GatsbyImage
                        image={getImage(post.featuredImage.node.localFile)}
                        alt={post.title ?? 'Post Thumbnail'}
                        className="col-span-4 mb-2 rounded-md"
                        imgClassName="rounded-md"
                      />
                    ) : (
                      <StaticImage
                        src="../../assets/placeholders/placeholder-720-480.png"
                        alt="placeholder"
                        width={150}
                        height={85}
                        className="col-span-4 mb-2 rounded-md"
                        imgClassName="rounded-md"
                      />
                    )}
                    <span
                      className={`col-span-6 ml-4 font-medium group-hover:text-link-hover ${TailwindTransitions.default[300]}`}
                    >
                      {post.title}
                    </span>
                  </UniversalLink>
                </li>
              ))}
          </ul>
        </CardWrapper>
      )}
    </>
  );
};

export default memo(PostWidgetLatestPosts);
