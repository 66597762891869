import {graphql, useStaticQuery} from 'gatsby';

const useGetLatestPosts = () => {
  return useStaticQuery(graphql`
    query {
      # Limit must greater than expected number 1 unit
      latestPosts: allWpPost(limit: 11, sort: {order: DESC, fields: date}) {
        nodes {
          title
          id
          uri
          date(formatString: "DD.MM.YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 150, height: 85)
                }
              }
            }
          }
        }
      }
    }
  `);
};

export default useGetLatestPosts;
