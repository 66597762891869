import {useEffect, useState} from 'react';
import AppConfig from '../config/appConfig';

/**
 * Track headings on current page using IntersectionObserver and set active ID
 * @param {()=>{}} setActiveId A state setter function to set active ID
 */
const useHighLightActiveHeading = (
  postSelector = '',
  headingSelector = 'h2,h3,h4,h5,h6'
) => {
  const isSSR = typeof window == 'undefined';

  const [inViewId, setInViewId] = useState();
  useEffect(() => {
    if (isSSR) return;

    const inViewSet = new Map();

    const callback = (changes) => {
      for (const change of changes) {
        change.isIntersecting
          ? inViewSet.set(change.target.id, change.target)
          : inViewSet.delete(change.target.id);
      }

      // Get inView elements
      const inView = Array.from(inViewSet.entries())
        .filter(([id, _]) => !!id)
        .map(([id, el]) => [id, el.offsetTop]);

      if (inView.length > 0) {
        setInViewId(
          inView.reduce((acc, next) => (next[1] < acc[1] ? next : acc))[0]
        );
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: `-${AppConfig.navStickyHeight}px 0px -40% 0px`,
    });

    for (const element of document
      .querySelector(postSelector)
      .querySelectorAll(headingSelector)) {
      observer.observe(element);
    }
    return () => observer.disconnect();
  }, [isSSR, postSelector, headingSelector]);

  return {inViewId};
};

export default useHighLightActiveHeading;
