import React, {memo} from 'react';
import AppConfig from '../../config/appConfig';
import useHighLightActiveHeading from '../../hooks/useHighLightActiveHeading';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import TableOfContent from './TableOfContent';

const defaultStyle = {
  className: '',
  itemClassName: `${TailwindTransitions.default[100]} hover:text-link-hover`,
  activeItemClassName: 'text-link',
};

/**
 * A Table of Content that highlight active heading
 * @param {Object} props
 * @param {Object} props.toc Table of Content Object
 * @param {Object} props.style Class name for this component
 * @param {string} props.style.className Wrapper class name
 * @param {string} props.style.titleClassName Title class name.
 * @param {string} props.style.contentClassName Class name of content section
 * @param {string} props.style.itemClassName Item class name
 * @param {string} props.style.activeItemClassName active item class name
 * @param {string} props.titleText Title text
 * @param {number} props.depth Max display depth based on heading level. From 2-6.
 *
 * Default value is 6.
 * @param {()=>{}} props.onItemClicked Handler when an item is clicked
 * @returns
 */
const TableOfContentActiveHeading = ({
  toc,
  style = defaultStyle,
  titleText,
  depth = 6,
  onItemClicked,
}) => {
  // Merge input style with default style
  style = {...defaultStyle, ...style};

  const {inViewId} = useHighLightActiveHeading(
    '#post-content',
    AppConfig.postWidgetTocDisplayTag
  );

  return (
    <TableOfContent
      activeId={inViewId}
      toc={toc}
      style={style}
      titleText={titleText}
      depth={depth}
      onItemClicked={onItemClicked}
    />
  );
};

export default memo(TableOfContentActiveHeading);
