import React, {useState} from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import UniversalLink from '../_baseComponents/Link/UniversalLink';

/**
 *
 * @param {Object} props
 * @param {className} props.className Class Single Icon
 * @param {string} props.url URL
 * @param {JSX.Element} props.Icon Icon component
 * @param {string} props.ariaLabel icon label for accessibility
 * @param {string} props.tooltipText Contain text for tooltip
 * @param {string} props.isClipBoard Check if item is copy to clipboard
 * @returns
 */
const SingleSocialIcon = ({
  className,
  url,
  Icon,
  ariaLabel = '',
  tooltipText,
  isClipBoard = false,
}) => {
  let Tag = Icon;
  let onClickHandle = (e) => {};
  let onMouseLeaveHandle = (e) => {};

  const [tooltip, setTooltip] = useState(tooltipText);

  if (isClipBoard) {
    onClickHandle = () => {
      navigator.clipboard.writeText(window.location.href);
      setTooltip('Copied');
    };

    onMouseLeaveHandle = () => {
      // Delay .5s for fade out transtion
      setTimeout(() => {
        setTooltip(tooltipText);
      }, 500);
    };
  }

  return (
    <UniversalLink
      to={url}
      escapeEmptyLink
      target={isClipBoard ? '' : '_blank'}
      className={`${className} ${TailwindTransitions.default[300]} rounded-md text-xl text-gray-500`}
      aria-label={ariaLabel}
      onClick={onClickHandle}
      onMouseLeave={onMouseLeaveHandle}
    >
      <span className="group relative">
        {tooltipText?.length > 0 && (
          <span
            className={`${TailwindTransitions.default[300]} invisible absolute bottom-8 left-1/2 right-1/2 w-24 -translate-x-1/2 rounded-md bg-gray-400 px-3 py-1 text-center text-xs text-white opacity-0 after:absolute after:left-1/2 after:top-full after:-ml-1 after:border-4 after:border-transparent after:border-t-gray-400 group-hover:visible group-hover:opacity-100`}
            id="tooltip"
          >
            {tooltip}
          </span>
        )}
        <Tag />
      </span>
    </UniversalLink>
  );
};

export default SingleSocialIcon;
