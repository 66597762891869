import React, {memo, useContext, useEffect, useState} from 'react';
import {Rating} from 'react-simple-star-rating';
import {addRating} from '../../api/ratings/post-ratings';
import FirebaseAuthContext from '../../store/firebase-auth-context';
import IconStarEmpty from '../Icon/IconStarEmpty';
import IconStarFilled from '../Icon/IconStarFilled';

/**
 * Calculate average and round result to 1 decimal number
 * @param {[Float]} ratings all rating of a post
 * @returns average rating rounded to 1 decimal number
 */
function calculateRating(ratings) {
  let averageRating = ratings.reduce((p, c) => p + c, 0) / ratings.length;
  return ratings?.length ? Math.round(averageRating * 10) / 10 : 0;
}

/**
 * Log in to Firebase using default admin account and get auth token, refresh token and expiration time
 * @param {(token, expirationTime) => {}} contextLogin login function of context hook to store auth token and expiration time
 * @returns new auth token
 */
const loginWithFirebase = async (
  contextLogin = (token, expirationTime) => {}
) => {
  try {
    const response = await fetch('/api/ratings/firebase-auth', {
      method: 'POST',
    });

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message || 'Could not log in.');
    }

    contextLogin(result.token, result.expirationTime);
    return result.expirationTime;
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Create a rating component. Use 3rd library react-simple-star-rating for UI
 * and firebase to store rating data
 * Reference: https://github.com/awran5/react-simple-star-rating
 * @param {Object} props
 * @param {String} props.postId Post Id
 * @param {[Float]} props.initialRatings initial rating values
 * @param {string} props.className Class names of wrapper
 * @param {number | string} props.iconSize Size for this icon. In pixel (number) or em/rem (string). Eg: 1: 1px, '2rem': 2rem
 * @returns
 */
const PostRating = ({
  postId,
  initialRatings,
  className = '',
  iconSize = 20,
}) => {
  const authContext = useContext(FirebaseAuthContext);
  const [rating, setRating] = useState(calculateRating(initialRatings));

  useEffect(() => {
    // login to Firebase if not log in or token expired
    if (!authContext.token || authContext.token.length === 0)
      try {
        loginWithFirebase(authContext.login);
      } catch (error) {
        console.log('Post Rating Error: ', error);
      }
  }, [authContext.login, authContext.token]);

  const handleOnClick = async (rate) => {
    // add new rating to ratings array
    initialRatings.push(rate);
    // set new rating value
    setRating(calculateRating(initialRatings));
    // send new rating to firebase
    try {
      if (authContext.getRemainingTime() > 10000) {
        addRating(postId, rate, authContext.token);
      } else {
        const newToken = await loginWithFirebase(authContext.login);
        addRating(postId, rate, newToken);
      }
    } catch (error) {
      console.log('Post Rating Error: ', error);
    }
  };

  return (
    <div className={`flex w-full justify-end items-center ${className}`}>
      <Rating
        transition
        allowFraction
        titleSeparator="/"
        initialValue={rating}
        onClick={handleOnClick}
        fillIcon={
          <IconStarFilled size={iconSize} className="mr-1 inline-block" />
        }
        emptyIcon={
          <IconStarEmpty size={iconSize} className="mr-1 inline-block" />
        }
        fillColor={'orange'}
      />
      {rating > 0 && (
        <div className="mx-2 mt-1">{`${rating}/5 - (${initialRatings.length} đánh giá)`}</div>
      )}
    </div>
  );
};

export default memo(PostRating);
