import React, {memo} from 'react';
import TableOfContentActiveHeading from '../TableOfContent/TableOfContentActiveHeading';
import CardWrapper from '../Wrapper/CardWrapper';

/**
 * Table of Content widget for post
 * @param {Object} props
 * @param {Object} props.toc Table of Content object
 * @param {Object} props.className Class name
 * @param {Object} props.contentClassName Content class name
 * @returns
 */
const PostWidgetTableOfContent = ({
  className = 'bg-white/30 p-5 lg:p-6',
  contentClassName = 'scrollbar-none max-h-[calc(85vh-6rem)] overflow-y-auto',
  toc,
}) => {
  return (
    <>
      {toc.children?.length > 0 && (
        <CardWrapper className={`${className}`}>
          <TableOfContentActiveHeading
            toc={toc}
            depth={3}
            titleText={'Nội dung'}
            style={{contentClassName: contentClassName}}
          />
        </CardWrapper>
      )}
    </>
  );
};

export default memo(PostWidgetTableOfContent);
