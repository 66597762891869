const TocError = {
  CHILD_INVALID: 'CHILD_INVALID',
};
const TocErrorMsg = {
  CHILD_INVALID:
    'Child heading is invalid, please double check the parent and child heading level',
};

module.exports = {
  TocError,
  TocErrorMsg,
};
