import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';
import React, {memo} from 'react';
import AppConfig from '../../config/appConfig';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import UniversalLink from '../_baseComponents/Link/UniversalLink';

const PostRelated = ({relatedTagPosts, relatedCategoryPosts, className}) => {
  const maxNoPosts = AppConfig.postRelatedLength ?? 6;
  const maxTagPost = AppConfig.postRelatedMaxNumTagPosts ?? 3;

  // get tag posts
  let slicedRelatedTagPosts = relatedTagPosts.slice(0, maxTagPost);
  // get category post (not exist in tag posts)
  let filteredRelatedCategoryPosts = relatedCategoryPosts.filter(
    (categoryPost) =>
      !slicedRelatedTagPosts.some((tagPost) => tagPost.id === categoryPost.id)
  );
  // get related post
  const relatedPosts = [
    ...slicedRelatedTagPosts,
    ...filteredRelatedCategoryPosts,
  ].slice(0, maxNoPosts);

  return (
    <div className={`${className ?? ''}`}>
      <h2 className="text-xl font-medium">Bài viết liên quan</h2>
      <div className="grid grid-cols-2 gap-5 overflow-hidden pt-5 md:grid-cols-4">
        {relatedPosts.map((post) => (
          <article key={post.id} className="group">
            <UniversalLink
              to={post.uri}
              className="flex flex-col items-stretch"
            >
              {post.featuredImage?.node ? (
                <GatsbyImage
                  image={getImage(post.featuredImage.node.localFile)}
                  alt={post.title ?? 'Post Thumbnail'}
                  className="mb-2 rounded-xl"
                  imgClassName="rounded-xl"
                />
              ) : (
                <StaticImage
                  src="../../assets/placeholders/placeholder-720-480.png"
                  alt="placeholder"
                  width={300}
                  height={170}
                  className="mb-2 rounded-xl"
                  imgClassName="rounded-xl"
                />
              )}
              <span
                className={`font-medium group-hover:text-link-hover ${TailwindTransitions.default[300]}`}
              >
                {post.title}
              </span>
            </UniversalLink>
          </article>
        ))}
      </div>
    </div>
  );
};

export default memo(PostRelated);
