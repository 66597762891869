import React from 'react';
/**
 * A Styled Heading Tag. Supported: p, h2, h3
 * @param {Object} props Props
 * @param {String} props.tag Html tag to display
 * @param {String} props.className Custom class names
 * @returns
 */
const StyledHeading = ({tag, className, children}) => {
  let supportedHeadings = ['p', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const Tag =
    supportedHeadings.find((heading) => heading === tag) ??
    supportedHeadings[0];

  return (
    <Tag
      className={`${
        className ?? ''
      } before:relative before:top-[1.5em] before:left-[-0.3em] before:block before:h-[0.8em] before:w-[0.8em] before:rounded-sm before:bg-primary before:opacity-20`}
    >
      {children}
    </Tag>
  );
};

export default StyledHeading;
