import React from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import CardWrapper from '../Wrapper/CardWrapper';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import StyledHeading from '../_baseComponents/StyledHeading/StyledHeading';

/**
 *
 * @param {Object} props
 * @param {Object} props.tags
 * @returns
 */
const PostWidgetTags = ({tags, className = ' bg-white/30 p-5'}) => {
  return (
    <>
      {tags?.length > 0 && (
        <CardWrapper className={`${className}`}>
          <StyledHeading tag="p" className="text-xl font-semibold">
            Từ khoá
          </StyledHeading>
          <div className="flex flex-row flex-wrap gap-2">
            {tags.map((tag) => (
              <UniversalLink
                key={tag.uri}
                to={tag.uri}
                className={`${TailwindTransitions.default[300]} text-xs hover:text-link-hover`}
              >
                <span className="rounded-lg bg-[#1F243808] px-2 py-1">
                  {tag.name}
                </span>
              </UniversalLink>
            ))}
          </div>
        </CardWrapper>
      )}
    </>
  );
};

export default PostWidgetTags;
