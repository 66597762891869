import React, {memo, useCallback, useRef} from 'react';
import StyledHeading from '../_baseComponents/StyledHeading/StyledHeading';
import TableOfContentItem from './TableOfContentItem';

const defaultStyle = {
  className: 'my-5 p-3',
  titleClassName: 'text-xl font-semibold',
  contentClassName: '',
  itemClassName: '',
  activeItemClassName: '',
};

/**
 * A Table of Content component
 * @param {Object} props
 * @param {Object} props.toc Table of Content object
 * @param {Object} props.style Class name for this component
 * @param {string} props.style.className Wrapper class name
 *
 * Default: `my-5 p-3`
 * @param {string} props.style.titleClassName Title class name.
 *
 * Default: `text-xl font-semibold`
 * @param {string} props.style.contentClassName Class name of content section
 * @param {string} props.style.itemClassName Item class name
 * @param {string} props.style.activeItemClassName active item class name
 * @param {string} props.titleText Title text
 * @param {string} props.activeId Active ID
 * @param {number} props.depth Max display depth based on heading level. From 2-6.
 *
 * Default value is 6.
 * @param {()=>{}} props.onItemClicked Handler when an item is clicked
 * @returns
 */
const TableOfContent = ({
  toc,
  style = defaultStyle,
  titleText,
  activeId,
  depth = 6,
  onItemClicked,
}) => {
  // Merge input style with default style
  style = {...defaultStyle, ...style};

  const scrollRef = useRef(null);

  // const scroll = useCallback(
  //   (to) => {
  //     scrollRef.current?.scrollTo({
  //       top: to - scrollRef.current.offsetTop,
  //       behavior: 'smooth',
  //     });
  //   },
  //   [scrollRef]
  // );

  return (
    <div className={`${style.className ?? ''} `}>
      {titleText && (
        <StyledHeading tag="p" className={`mb-5 ${style.titleClassName}`}>
          {titleText}
        </StyledHeading>
      )}
      <div ref={scrollRef} className={`${style.contentClassName}`}>
        <ul>
          {toc.children.map((item) => (
            <li key={item.id} className="mb-2 flex flex-col last:mb-0">
              <TableOfContentItem
                className={style.itemClassName}
                activeClassName={style.activeItemClassName}
                activeId={activeId}
                tocItem={item}
                currentDepth={2}
                depth={depth}
                onClick={onItemClicked}
                // scrollToActiveItem={scroll}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(TableOfContent);
