import React, {useEffect, useRef} from 'react';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import {TocErrorMsg} from './tocErrors';

/**
 * A Table of Content Item component
 * @param {Object} props
 * @param {Object} props.tocItem Toc Item
 * @param {string} props.tocItem.id
 * @param {string} props.tocItem.title
 * @param {Object[]} props.tocItem.children
 * @param {string} props.tocItem.errorCode
 * @param {string} props.activeId ID of active heading
 * @param {string} props.className
 * @param {string} props.activeClassName
 * @param {number} props.currentDepth Depth of current item
 * @param {number} props.depth Max render depth. From 2-6.
 *
 * Default value is 6.
 * @param {(scrollTo)=>{}} props.scrollToActiveItem Callback to scroll active heading to the top of scroll container
 * @param {()=>{}} props.onClick Handler for mouse click event
 * @returns
 */
const TableOfContentItem = ({
  activeId,
  tocItem,
  className,
  activeClassName,
  currentDepth,
  depth = 6,
  scrollToActiveItem = () => {},
  onClick = () => {},
}) => {
  const {id, title, children, errorCode} = tocItem;
  const itemRef = useRef(null);

  useEffect(() => {
    if (activeId === id && itemRef.current) {
      scrollToActiveItem(itemRef.current.offsetTop);
    }
  }, [activeId, itemRef, id, scrollToActiveItem]);

  return (
    <>
      {errorCode ? (
        <p className="mb-2 text-red-500" ref={itemRef}>
          {TocErrorMsg[errorCode]}
        </p>
      ) : (
        <>
          <a
            ref={itemRef}
            href={`#${id}`}
            className={`${className} ${activeId === id && activeClassName} ${
              // render child only when child depth in valid depth
              children?.length > 0 && currentDepth <= depth ? 'mb-2' : ''
            }`}
            onClick={onClick}
          >
            {title}
          </a>
          {children?.length > 0 && currentDepth <= depth && (
            <ul className={`ml-4`}>
              {children.map((item) => (
                <li key={item.id} className="mb-2 flex flex-col last:mb-0">
                  <TableOfContentItem
                    className={className}
                    activeClassName={activeClassName}
                    activeId={activeId}
                    tocItem={item}
                    currentDepth={currentDepth + 1}
                    depth={depth}
                    onClick={onClick}
                    scrollToActiveItem={scrollToActiveItem}
                  />
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default TableOfContentItem;
