/**
 * Get all rating of a post from Firebase
 * @param {String} postId post ID
 * @returns array contains ratings
 */
const getPostRating = async (postId) => {
  const response = await fetch(
    `${process.env.GATSBY_GG_FIREBASE_REALTIME_DB_URL}/posts/${postId}/ratings.json`
  );
  const ratings = await response.json();

  if (!response.ok) {
    throw new Error(
      ratings.message || 'Could not fetch ratings of post ' + postId
    );
  }
  let loadedRatings = [];

  for (const key in ratings) {
    loadedRatings.push(ratings[key]);
  }

  return loadedRatings;
};

/**
 * Add new rating to Firebase
 * @param {String} postId post ID
 * @param {Float} rating rating
 * @param {String} firebaseIDToken Firebase ID Token
 * @returns
 */
const addRating = async (postId, rating, firebaseIDToken) => {
  if (!firebaseIDToken || firebaseIDToken.trim().length === 0) {
    console.log('Post Rating Error: Please log in to Firebase first!');
  }

  const response = await fetch(
    `${process.env.GATSBY_GG_FIREBASE_REALTIME_DB_URL}/posts/${postId}/ratings.json?auth=${firebaseIDToken}`,
    {
      method: 'POST',
      body: JSON.stringify(rating),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not add rating.');
  }

  return null;
};

module.exports = {
  getPostRating,
  addRating,
};
