import React from 'react';

/**
 * A Full width Flex Layout with 3 columns:
 *
 * - Left Column: has 2 sections, one is normal zone, one is sticky zone.
 *
 * - Middle Column: The main content of the layout.
 *
 * - Right Column: has 2 sections, one is normal zone, one is sticky zone.
 *
 * Anything that wrapped by this Layout will be displayed below 3 main columns.
 * @param {Object} props
 * @param {JSX.Element} props.LeftNotSticky Component to be displayed in `Left Sidebar`, in `No sticky zone`
 * @param {JSX.Element} props.LeftSticky Component to be displayed in `Left Sidebar`, in `Sticky zone`
 * @param {JSX.Element} props.MainContent Component to be displayed in `Middle Column` or `Main Content` zone
 * @param {JSX.Element} props.RightNotSticky Component to be displayed in `Right Sidebar`, in `No sticky zone`
 * @param {JSX.Element} props.RightSticky Component to be displayed in `Right Sidebar`, in `Sticky zone`
 * @param {string} props.leftClassName Class name for wrapper of `Left Sidebar`
 * @param {string} props.leftStickyClassName Class name for sticky wrapper of `Left Sidebar`
 * @param {string} props.mainClassName Class name for wrapper of `Main Content`
 * @param {string} props.rightClassName Class name for wrapper of `Right Sidebar`
 * @param {string} props.rightStickyClassName Class name for sticky wrapper of `Right Sidebar`
 * @param {JSX.Element} props.children The content that will be displayed below `Main content` zone
 * @returns
 */
const Flex3ColsLayout = ({
  LeftNotSticky = () => <></>,
  LeftSticky = () => <></>,
  MainContent = () => <></>,
  RightNotSticky = () => <></>,
  RightSticky = () => <></>,
  children,
  className,
  leftClassName = 'flex-none w-fit',
  leftStickyClassName = '',
  mainClassName,
  rightClassName = 'flex-none w-fit',
  rightStickyClassName = '',
}) => {
  return (
    <div className={`${className ?? ''}`}>
      <div className="flex">
        {/* Left Col */}
        <div className={`${leftClassName ?? ''}`}>
          <LeftNotSticky />
          {LeftSticky && (
            <div className={`sticky top-24 overflow-auto ${leftStickyClassName}`}>
              <LeftSticky />
            </div>
          )}
        </div>

        {/* Middle Col */}
        <div className={`flex-1 ${mainClassName ?? ''}`}>
          <MainContent />
        </div>
        
        {/* Right Col */}
        <div className={`${rightClassName ?? ''}`}>
          <RightNotSticky />
          {RightSticky && (
            <div className={`sticky top-24 overflow-auto ${rightStickyClassName}`}>
              <RightSticky />
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Flex3ColsLayout;
