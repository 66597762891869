import loadable from '@loadable/component';
import {TbCalendarEvent} from '@react-icons/all-files/tb/TbCalendarEvent';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import React, {useCallback, useContext, useEffect} from 'react';
import Breadcrumb from '../components/BreadCrumb/BreadCrumb';
import Flex3ColsLayout from '../components/Layout/Flex3ColsLayout';
import PostRating from '../components/PostRating/PostRating';
import PostRelated from '../components/PostRelated/PostRelated';
import PostWidgetLatestPosts from '../components/PostWidgets/PostWidgetLatestPosts';
import PostWidgetTableOfContent from '../components/PostWidgets/PostWidgetTableOfContent';
import PostWidgetTags from '../components/PostWidgets/PostWidgetTags';
import Seo from '../components/Seo/Seo';
import SocialIconsSharePost from '../components/SocialIcons/SocialIconsSharePost';
import CardWrapper from '../components/Wrapper/CardWrapper';
import UniversalLink from '../components/_baseComponents/Link/UniversalLink';
import useCheckBreakpoint from '../hooks/useCheckBreakpoint';
import PostContext from '../store/post-context';
import {TailwindTransitions} from '../utils/library/transitions/transitions';

// import lazy
const OverlayTableOfContent = loadable(() =>
  import('../components/TableOfContent/OverlayTableOfContent')
);

// const FacebookComment = lazy(() =>
//   import('../components/Comment/FacebookComment')
// );

const PostTemplate = ({
  data: {post, relatedTagPosts, relatedCategoryPosts},
}) => {
  const isSSR = typeof window === 'undefined';

  const postContext = useContext(PostContext);

  // only show Toc Button on mobile & tablet devices
  const {isDesktop, isTablet, isMobile} = useCheckBreakpoint();

  useEffect(() => {
    if (post.toc?.children?.length > 0 && !isDesktop) {
      postContext.setIsShowTocBtn(true);
    }

    return () => {
      postContext.setIsShowTocBtn(false);
    };
  }, [postContext, post.toc?.children?.length, !isDesktop]);

  const tocOverlayCloseClickHandler = useCallback(
    () => postContext.setIsOpenOverlayToc(false),
    [postContext]
  );
  const tocOverlayItemClickHandler = useCallback(
    () => postContext.setIsOpenOverlayToc(false),
    [postContext]
  );

  return (
    <>
      <Flex3ColsLayout
        LeftSticky={() => (
          <RenderLeftSticky post={post} className="hidden lg:block" />
        )}
        MainContent={() => <RenderMainContent post={post} isSSR={isSSR} />}
        RightSticky={() => (
          <RenderRightSticky post={post} className="hidden lg:flex" />
        )}
        leftClassName="hidden flex-1 xl:block xl:max-w-[calc(100vw*2/10)]"
        leftStickyClassName="-mb-8 -mr-5 px-5 pb-8"
        mainClassName="px-5 max-w-full lg:max-w-[calc(100vw*8/12)] xl:max-w-[calc(100vw*6/10)]"
        rightClassName="hidden lg:block lg:max-w-[calc(100vw*4/12)] lg:flex-1 xl:max-w-[calc(100vw*2/10)]"
        rightStickyClassName="-mb-8 -ml-5 px-5 pb-8"
        className="pt-[70px] lg:pt-20"
      >
        <div className="mt-4 flex flex-wrap">
          {/* Left col  */}
          <div className="hidden flex-1 xl:block xl:max-w-[calc(100vw*2/10)]"></div>
          {/* Main Content */}
          <div className="flex-1 px-4">
            <CardWrapper
              className={`p-5 md:bg-white/30 lg:p-8`}
              breakpoint="md"
            >
              <PostRelated
                relatedCategoryPosts={relatedCategoryPosts.nodes}
                relatedTagPosts={relatedTagPosts.nodes}
                className="text-sm"
              />
            </CardWrapper>
          </div>
          {/* Right col */}
          <div className="hidden lg:block lg:max-w-[calc(100vw*4/12)] lg:flex-1 xl:max-w-[calc(100vw*2/10)]"></div>
        </div>
      </Flex3ColsLayout>

      {/* Lazy Components */}
      <OverlayTableOfContent
        toc={post.toc}
        isOpen={postContext.isOpenOverlayToc}
        onCloseClicked={tocOverlayCloseClickHandler}
        onItemClicked={tocOverlayItemClickHandler}
      />
    </>
  );
};

export default PostTemplate;

/**
 * Left Col - Sticky Zone
 * @param {Object} props
 * @param {Object} props.post Post object
 * @returns
 */
const RenderLeftSticky = ({post, className = 'block'}) => (
  <div className={`${className}`}>
    {post.toc?.children?.length > 0 && (
      <PostWidgetTableOfContent
        toc={post.toc}
        className={`max-h-[calc(95vh-6rem)] overflow-hidden bg-white/30 p-5 text-sm lg:p-6`}
        contentClassName={`scrollbar-none max-h-[calc(80vh-6rem)] overflow-y-auto`}
      />
    )}
  </div>
);

/**
 *
 * @param {Object} props
 * @param {Object} props.post Post object
 * @param {boolean} props.isSSR Check if is in Sever Side Rendering state or not
 * @returns
 */
const RenderMainContent = ({post, isSSR}) => (
  <CardWrapper
    className={`flex h-full flex-col justify-between p-5 md:bg-white/30 lg:p-8`}
    breakpoint="md"
  >
    {/* Post Section Start */}
    <article className="">
      <div className="flex flex-col gap-4">
        {/* For BreadCrumb */}
        {post.seo ? (
          <Breadcrumb breadcrumbs={post.seo.breadcrumbs} />
        ) : // TODO: Create breadcrumb when not using yoastSEO
        null}

        {/* Post Title */}
        <h1
          dangerouslySetInnerHTML={{__html: post.title}}
          className="text-2xl font-bolder md:text-3xl"
        />

        {/* Post Tags temp
        {post.tags ? (
          <PostTags
            tags={post.tags?.nodes}
            isShowIcon={false}
            textSize="text-xs"
          />
        ) : null} */}

        {/* Metadata */}
        <div className="flex w-[100%] flex-row items-center justify-between md:gap-4 lg:gap-5">
          <div className="flex flex-col justify-center gap-1 md:flex-row md:items-center md:gap-2">
            {/* Author */}
            <UniversalLink
              to={post.author.node.uri}
              className="group flex flex-row items-center justify-center gap-2"
            >
              <img
                className="aspect-square h-5 w-5 rounded-full"
                src={post.author.node.avatar.url}
                alt="Author avatar"
              />
              <p
                className={`${TailwindTransitions.default[300]} text-sm font-medium group-hover:text-link-hover md:text-base`}
              >
                {post.author.node.name}
              </p>
            </UniversalLink>

            {/* Dot */}
            <div className="h-0 w-0 rounded-full bg-slate-300 md:h-1 md:w-1"></div>

            {/* Date */}
            <div className="flex flex-row gap-1">
              <TbCalendarEvent className="h-4 w-4 stroke-gray-400" />
              <p
                dangerouslySetInnerHTML={{__html: post.date}}
                className="text-xs leading-[18px] text-gray-400"
              />
            </div>
          </div>
          <div className="">
            {/* Social Icons */}
            <SocialIconsSharePost postUrl={post.slug} />
          </div>
        </div>
      </div>

      {/* Post Feature Image */}
      {post.featuredImage?.node && (
        <div className="my-10 rounded-lg">
          <GatsbyImage
            image={getImage(post.featuredImage.node.localFile)}
            alt={post.title ?? 'Post Thumbnail'}
            className="rounded-lg"
            imgClassName="rounded-lg"
          />
          <figcaption
            dangerouslySetInnerHTML={{
              __html: post.featuredImage.node.caption,
            }}
            className="mt-2 text-center text-sm"
          />
        </div>
      )}

      {/* Post Content */}
      <div
        id="post-content"
        className=""
        dangerouslySetInnerHTML={{__html: post.content}}
      />
    </article>

    <div className="mt-10">
      {/* Post Rating */}
      <PostRating
        initialRatings={post.ratings}
        postId={post.id}
        className="mb-4 text-sm"
        iconSize={16}
      />
    </div>

    {/* Comment */}
    {/* {!isSSR && (
      <Suspense fallback={<div />}>
        <hr className="mt-10" />
        <FacebookComment type="post" id={post.id} className="mt-5" />
      </Suspense>
    )} */}
  </CardWrapper>
);

/**
 * Right Col - Sticky Zone
 * @param {Object} props
 * @param {Object} props.post Post object
 * @returns
 */
const RenderRightSticky = ({post, className = 'flex'}) => (
  <div className={`flex-col ${className}`}>
    <PostWidgetLatestPosts
      postId={post.id}
      className={`max-h-[calc(70vh-6rem)] bg-white/40 p-5 text-sm overflow-y-hidden`}
      listClassName="overflow-y-auto scrollbar-none max-h-[calc(55vh-6rem)]"
    />

    {post.tags?.nodes?.length > 0 && (
      <PostWidgetTags
        tags={post.tags.nodes}
        className={'mt-4 flex flex-col flex-wrap gap-4 bg-white/40 p-5 text-sm'}
      />
    )}
  </div>
);

export const Head = ({data: {post}}) => (
  <Seo
    yoastSeo={post.seo}
    title={post.title}
    uri={post.uri}
    description={post.content && post.content.substring(150)}
    rating={post.ratings}
  />
);

export const pageQuery = graphql`
  fragment relatedPostItem on WpPost {
    title
    id
    uri
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300, height: 170)
          }
        }
      }
    }
  }

  query ($id: String!, $tags: [String!], $categories: [String!]) {
    post: wpPost(id: {eq: $id}) {
      id
      title
      toc
      content
      uri
      slug
      author {
        node {
          name
          uri
          avatar {
            url
          }
        }
      }
      date(formatString: "DD.MM.YYYY")
      categories {
        nodes {
          id
          name
          uri
        }
      }
      tags {
        nodes {
          id
          name
          uri
        }
      }
      seo {
        title
        fullHead
        canonical
        breadcrumbs {
          text
          url
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          caption
        }
      }
      ratings
    }
    relatedTagPosts: allWpPost(
      filter: {tags: {nodes: {elemMatch: {id: {in: $tags}}}}}
      sort: {order: DESC, fields: date}
      limit: 10
    ) {
      nodes {
        ...relatedPostItem
      }
    }
    relatedCategoryPosts: allWpPost(
      filter: {categories: {nodes: {elemMatch: {id: {in: $categories}}}}}
      sort: {order: DESC, fields: date}
      limit: 10
    ) {
      nodes {
        ...relatedPostItem
      }
    }
  }
`;
