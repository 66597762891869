import React, {memo} from 'react';
import {ImPinterest2} from '@react-icons/all-files/im/ImPinterest2';
import {AiOutlineReddit} from '@react-icons/all-files/ai/AiOutlineReddit';
import {CgMail} from '@react-icons/all-files/cg/CgMail';
import {ImFacebook} from '@react-icons/all-files/im/ImFacebook';
import {FaLinkedin} from '@react-icons/all-files/fa/FaLinkedin';
import {FaTelegram} from '@react-icons/all-files/fa/FaTelegram';
import {FaTwitter} from '@react-icons/all-files/fa/FaTwitter';
import {BiLinkAlt} from '@react-icons/all-files/bi/BiLinkAlt';

import SingleSocialIcon from './SingleSocialIcon';

const SocialIconsSharePost = ({postUrl, className = ''}) => {
  const facebookSharingUrl = `https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_FRONT_END_ENDPOINT}/${postUrl}`;
  const twitterSharingUrl = `https://twitter.com/intent/tweet?url=${process.env.GATSBY_FRONT_END_ENDPOINT}${postUrl}&text=${postUrl}`;
  const linkedinSharingUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${process.env.GATSBY_FRONT_END_ENDPOINT}${postUrl}`;
  const redditSharingUrl = `https://www.reddit.com/submit?url=${process.env.GATSBY_FRONT_END_ENDPOINT}${postUrl}&title=${postUrl}`;
  const telegramSharingUrl = `https://t.me/share/url?url=${process.env.GATSBY_FRONT_END_ENDPOINT}${postUrl}&text=${postUrl}`;
  const emailSharingUrl = `mailto:?subject=${postUrl};body=${process.env.GATSBY_FRONT_END_ENDPOINT}${postUrl}`;

  const icons = [
    {
      sharingUrl: facebookSharingUrl,
      Icon: ImFacebook,
      socialIconClassName: 'hover:text-blue-700',
      label: 'Share via Facebook',
      active: true,
      tooltipText: 'Share via Facebook',
    },
    {
      sharingUrl: twitterSharingUrl,
      Icon: FaTwitter,
      socialIconClassName: 'hover:text-blue-400',
      label: 'Share via Twitter',
      active: true,
      tooltipText: 'Share via Twitter',
    },
    {
      sharingUrl: '#',
      Icon: ImPinterest2,
      socialIconClassName: 'hover:text-red-600',
      label: 'Share via Pinterest',
      active: false,
      tooltipText: 'Share via Pinterest',
    },
    {
      sharingUrl: linkedinSharingUrl,
      Icon: FaLinkedin,
      socialIconClassName: 'hover:text-blue-500',
      label: 'Share via Linkedin',
      active: true,
      tooltipText: 'Share via Linkedin',
    },
    {
      sharingUrl: redditSharingUrl,
      Icon: AiOutlineReddit,
      socialIconClassName: 'hover:text-orange-500',
      label: 'Share via Reddit',
      active: false,
      tooltipText: 'Share via Reddit',
    },
    {
      sharingUrl: telegramSharingUrl,
      Icon: FaTelegram,
      socialIconClassName: 'hover:text-blue-500',
      label: 'Share via Telegram',
      active: true,
      tooltipText: 'Share via Telegram',
    },
    {
      sharingUrl: emailSharingUrl,
      Icon: CgMail,
      socialIconClassName: 'hover:text-neutral-800',
      label: 'Share via Email',
      active: false,
      tooltipText: 'Share via Email',
    },
    {
      sharingUrl: '#',
      Icon: BiLinkAlt,
      socialIconClassName: 'hover:text-rose-500',
      label: 'Share via ClipBoard',
      active: true,
      tooltipText: 'Click to copy link',
      isClipBoard: true,
    },
  ];

  return (
    <div
      className={`${className} flex flex-row items-center justify-center gap-3`}
    >
      {icons
        .filter((item) => item.active === true)
        .map((icon, idx) => (
          <SingleSocialIcon
            key={idx}
            url={icon.sharingUrl}
            Icon={icon.Icon}
            className={icon.socialIconClassName}
            ariaLabel={icon.label}
            tooltipText={icon.tooltipText}
            isClipBoard={icon.isClipBoard}
          />
        ))}
    </div>
  );
};

export default memo(SocialIconsSharePost);
